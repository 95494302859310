import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Top from '../views/Top.vue'
import StudentList from '../views/StudentList.vue'
import StudentDetail from '../views/StudentDetail.vue'
import TextbookList from '../views/TextbookList.vue'
import StudentReg from '../views/StudentReg.vue'
import StudentEdit from '../views/StudentEdit.vue'
import BookDetail from '../views/BookDetail.vue'
import BookViewer from '../views/BookViewer.vue'
import SystemTop from '../views/SystemTop.vue'
import SystemClassroom from '../views/SystemClassroom.vue'
import SystemAccount from '../views/SystemAccount.vue'
import PageSelect from '../views/PageSelect.vue'
import LearnHisDetail from '../views/LearnHisDetail.vue'
import BookList from '../views/BookList.vue'
import BookReg from '../views/BookReg.vue'
import TagList from '../views/TagList.vue'
import StudentCreate from '../views/StudentCreate.vue'
import CreateAdmin from '../views/CreateAdmin.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Top',
    name: 'Top',
    component: Top
  },
  {
    path: '/StudentList',
    name: 'StudentList',
    component: StudentList
  },
  {
    path: '/StudentDetail',
    name: 'StudentDetail',
    component: StudentDetail
  },
  {
    path: '/TextbookList',
    name: 'TextbookList',
    component: TextbookList
  },
  {
    path: '/StudentReg',
    name: 'StudentReg',
    component: StudentReg
  },
  {
    path: '/StudentEdit',
    name: 'StudentEdit',
    component: StudentEdit
  },
  {
    path: '/BookDetail',
    name: 'BookDetail',
    component: BookDetail
  },
  {
    path: '/BookViewer',
    name: 'BookViewer',
    component: BookViewer
  },
  {
    path: '/SystemTop',
    name: 'SystemTop',
    component: SystemTop
  },
  {
    path: '/SystemClassroom',
    name: 'SystemClassroom',
    component: SystemClassroom
  },
  {
    path: '/SystemAccount',
    name: 'SystemAccount',
    component: SystemAccount
  },
  {
    path: '/PageSelect',
    name: 'PageSelect',
    component: PageSelect
  },
  {
    path: '/LearnHisDetail',
    name: 'LearnHisDetail',
    component: LearnHisDetail
  },
  {
    path: '/BookList',
    name: 'BookList',
    component: BookList
  },
  {
    path: '/BookReg',
    name: 'BookReg',
    component: BookReg
  },
  {
    path: '/CreateAdmin',
    name: 'CreateAdmin',
    component: CreateAdmin
  },
  {
    path: '/StudentCreate',
    name: 'StudentCreate',
    component: StudentCreate
  },
  {
    path: '/taglist',
    name: 'TagList',
    component: TagList
  }, 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
