<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav
            class="breadcrumb has-succeeds-separator"
            aria-label="breadcrumbs"
          >
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('StudentDetail')">生徒詳細</a></li>
              <li><a @click="page_push('TextbookList')">教材選択</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">ページ選択</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">ページ選択</h1>
        </div>
      </div>
      <br />      <div class="columns">
        <div class="column">
          <h1 class="title page-title">{{ text_book_title }}</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <table class="table tb-search">
            <tr>
              <td class="tb-middle s-input">
                <div class="tags">
                  <span class="tag is-medium is-light">漢字</span>
                  <span class="tag is-medium is-light">国語</span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <span class="subtitle is-5">印刷するページを選択してください</span>
        </div>
        <div class="column page-dis">
          <span>
            <input class="input is-medium" type="text" v-model="select_pages" />
          </span>
        </div>
        <div class="column">
          <button v-if="select_pages.length == 0" class="button is-dark is-rounded is-medium tb-btn" diabled>
            結合して確認する
          </button>
          <button v-else class="button is-primary is-rounded is-medium tb-btn"  @click="page_join">
            結合して確認する
          </button>    
        </div>
      </div>
      <hr class="hr" />
      <br />
      <div class="columns is-centered">
        <div class="column is-10">
          <div class="tile is-ancestor columns is-multiline">
            <div
              v-for="(page, index) in pages"
              :key="page"
              class="column tile is-parent is-3 book_content"
              @click="select_page(index)"
            >
              <article class="tile is-child">
                <figure class="image is-4by5">
                  <img
                    v-bind:class="{
                      'img-border': select_pages.includes(Number(index)),
                    }"
                    :src="page"
                  />
                </figure>
                <p class="title is-4">{{ index }}</p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      text_book_title: "",
      select_pages: [],
      pages: {},
    };
  },
  mounted: function () {
    window.scroll(0, 0);

    let selected_book_info = this.$store.state.selected_book_info;
    this.text_book_title = selected_book_info.title;

    let jsonbody = {};
    jsonbody.bookid = selected_book_info.bookid;

    this.axios
      .post(this.$store.state.url + "getpages",jsonbody,this.$store.state.post_json)
      .then((res) => {
        this.pages = res.data;
      });
  },
  methods: {
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    select_page: function (page) {
      if (this.select_pages.includes(Number(page))) {
        this.select_pages.splice(this.select_pages.indexOf(Number(page)), 1);
      } else {
        this.select_pages.push(Number(page));
      }
    },
    page_join: function () {
      let book_info = this.$store.state.selected_book_info;
      book_info.pages = this.select_pages;
      this.$store.commit("setBookInfo", book_info);
      this.$router.push({ name: "BookViewer" });
    },
  },
};
</script>
  