<template>
  <div>
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('SystemTop')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">タグ管理</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">タグ管理</h1>
        </div>
        <div class="column">
          <button class="button is-primary is-rounded registration-btn" @click="open_tag_modal('new')">
            <span>新規登録</span>
          </button>
        </div>
      </div>
      <br />
      <div class="columns is-centered">
        <div class="column is-12">
          <div class="tb-std">
            <table class="table is-fullwidth">
              <tr>
                <th>タグID</th>
                <th>タグ名</th>
                <th>登録日時</th>
                <th>更新日時</th>
                <th>操作</th>
              </tr>
              <tr v-for="tag in tag_lists" :key="tag">
                  <td>{{ tag.tag_id }}</td>
                  <td>{{ tag.name }}</td>
                  <td>{{ formatDatetime(tag.created_at) }}</td>
                  <td>{{ formatDatetime(tag.updated_at) }}</td>
                  <td>
                  <button class="ml-3 button is-info tb-btn" @click="open_tag_modal(tag)">編集</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div class="classroom-modal modal" v-bind:class="{ 'is-active': create_modal_is_open }">
        <div class="modal-background" @click="close_tag_modal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="close_tag_modal"></button>
          </header>
          <section class="modal-card-body">
            <p class="title is-4">タグの編集</p>
            <br />
            <div class="classroom-tb">
              <table class="table is-fullwidth">
                <tr>
                  <td class="has-text-right">タグ名</td>
                  <td class="has-text-left"><input class="input" type="text" v-model="tagname"/></td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-rounded is-medium">
              <span>戻る</span>
            </button>
            <button class="button is-primary is-rounded registration-btn is-medium" @click="create_tag">
              <span>登録</span>
            </button>
          </footer>
        </div>
      </div>

      <div class="classroom-modal modal" v-bind:class="{ 'is-active': edit_modal_is_open }">
        <div class="modal-background" @click="close_tag_modal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="close_tag_modal"></button>
          </header>
          <section class="modal-card-body">
            <p class="title is-4">タグの編集</p>
            <br />
            <div class="classroom-tb">
              <table class="table is-fullwidth">
                <tr>
                  <td class="has-text-right">タグID</td>
                  <td class="has-text-left">{{ selected_tag.tag_id }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">タグ名</td>
                  <td class="has-text-left"><input class="input" type="text" v-model="selected_tag.name"/></td>
                </tr>
                <tr>
                  <td class="has-text-right">登録日時</td>
                  <td class="has-text-left">
                    {{ formatDatetime(selected_tag.created_at)  }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-right">更新日時</td>
                  <td class="has-text-left">{{ formatDatetime(selected_tag.updated_at)  }}</td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-rounded is-medium">
              <span>戻る</span>
            </button>
            <button class="button is-danger is-rounded registration-btn is-medium" @click="delete_tag(selected_tag)">
              <span>削除</span>
            </button>
            <button class="button is-primary is-rounded registration-btn is-medium" @click="update_tag(selected_tag)">
              <span>更新</span>
            </button>
          </footer>
        </div>
      </div>


    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      tag_lists: [],
      tagname:'',
      selected_tag: {},
      edit_modal_is_open: false,
      create_modal_is_open:false,
    };
  },
  mounted:async function () {
    await this.gettaglist();
  },
  methods: {
    gettaglist: async function () {
      let jsonbody = {};
      await this.axios
        .post(this.$store.state.url + "tag", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.tag_lists = res.data;
          //console.log(res.data);
        });
    },
    create_tag: async function () {
      let jsonbody = {};
      jsonbody.name = this.tagname;
      await this.axios
        .post(this.$store.state.url + "tag/create", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.tagname= '';
          console.log(res.data);
          this.close_tag_modal();
          this.gettaglist();
        });
    },
    update_tag: async function (selected_tag) {
      let jsonbody = {};
      jsonbody.tag_id = selected_tag.tag_id;
      jsonbody.name = selected_tag.name;
      await this.axios
        .post(this.$store.state.url + "tag/update", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.selected_tag= {};
          console.log(res.data);
          this.close_tag_modal();
          this.gettaglist();
        });
    },
    delete_tag: async function (selected_tag) {
      let jsonbody = {};
      jsonbody.tag_id = selected_tag.tag_id;
      await this.axios
        .post(this.$store.state.url + "tag/delete", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.selected_tag= {};
          console.log(res.data);
          this.close_tag_modal();
          this.gettaglist();
        });
    },
    open_tag_modal: function (tag) {
      if (tag == 'new'){
        this.create_modal_is_open = true;
        console.log("new")
      }else{
        this.selected_tag = tag;
        this.edit_modal_is_open = true;
        console.log("edit")
      }
    },
    close_tag_modal: function () {
      console.log("sssssssss")
      this.edit_modal_is_open = false;
      this.create_modal_is_open = false;
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    formatDatetime(value) {
      if (!value) return '';
      return value.replace('T', ' ');
    },
  },
};
</script>
  