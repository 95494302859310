<template>
  <div>
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('SystemTop')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">教材管理</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">教材管理</h1>
        </div>
      </div>
      <br />
      <div class="columns is-centered">
        <div class="column is-12">
          <div class="tb-std">
            <table class="table is-fullwidth">
              <tr>
                <th>教材ID</th>
                <th>教材名</th>
                <th>サムネイル</th>
                <th>付録有無</th>
                <th>タグ</th>
                <th>更新日時</th>
                <th>操作</th>
              </tr>
              <tr v-for="book in book_lists" :key="book">
                <template v-if="book.title">
                  <td>{{ book.book_id }}</td>
                  <td>{{ book.title }}</td>
                  <td><img :src="book.thumnail_url" width="96" height="65"></td>
                  <td>{{ book.appendix_flg }}</td>
                  <td class="is-size-7">{{ changeTagIdToName(book.tag_id) }}</td>
                  <td>{{ formatDatetime(book.updated_at) }}</td>
                  <td>
                  <button class="ml-3 button is-info tb-btn" @click="bookditail(book)">編集</button>
                </td>
                </template>
                <template v-else>
                  <td>{{ book.book_id }}</td>
                  <td>未登録</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                  <button class="ml-3 button is-primary tb-btn" @click="bookditail(book)">登録</button>
                </td>
                </template>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      s3_book_lists: [],
      registerd_book_list: [],
      book_lists: [],
      tag_lists: [],
    };
  },
  mounted:async function () {
    await this.gets3booklist();
    await this.getRegisterdBooklist();
    await this.margeBookList();
    await this.gettaglist();
  },
  methods: {
    gets3booklist: async function () {
      let jsonbody = {};
      await this.axios
        .post(this.$store.state.url + "gets3booklist", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.s3_book_lists = res.data;
          //console.log(res.data);
        });
    },
    gettaglist: async function () {
      let jsonbody = {};
      await this.axios
        .post(this.$store.state.url + "tag", jsonbody, this.$store.state.post_json)
        .then((res) => {
          //扱いやすいようにキーバリューにしておく
          let returnobj = {};
          for (let i = 0; i < res.data.length; ++i){
            returnobj[res.data[i]['tag_id']] = res.data[i]['name']
          }
          this.tag_lists = returnobj
        });
    },
    getRegisterdBooklist: async function () {
      let jsonbody = {};
      await this.axios
        .post(this.$store.state.url + "book", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.registerd_book_list = res.data;
          //console.log(res.data);
        })
       .catch((err)=>  {
        // エラー時の処理
          console.log(err);
        });
    },
    margeBookList: function () {
      console.log(this.s3_book_lists)

      for (let i = 0; i < this.s3_book_lists.length; i++) {
        let margedObj = {}
        let s3Obj = {}
        let registeredObj = {}

        let s3_book_id = this.s3_book_lists[i]
        s3Obj["book_id"] = s3_book_id

        for (let j = 0; j < this.registerd_book_list.length; j++) {
          if (s3_book_id == this.registerd_book_list[j].book_id){
            registeredObj = this.registerd_book_list[j]
          }
        }

        margedObj = Object.assign({}, s3Obj, registeredObj);
        this.book_lists.push(margedObj);
      }
      //console.log(this.book_lists)
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    bookditail: function (book) {
      if (book.thumnail_url){
        this.$router.push({ name: "BookReg" , query: { "type": "update" ,"book_id": book.book_id } });
      }else{
        this.$router.push({ name: "BookReg" , query: { "type": "regist" ,"book_id": book.book_id } });
      }
    },
    formatDatetime(value) {
      if (!value) return '';
      return value.replace('T', ' ');
    },
    changeTagIdToName(tag_id_list) {
      let returnval = '';

      for (let i = 0; i < tag_id_list.length; ++i){
        returnval = returnval + ',' + this.tag_lists[tag_id_list[i]]
      }

      //つなぎのカンマを削除
      return returnval.slice(1)
    },
  },
};
</script>
  