<template>
  <div class="page-nav">
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item has-text-weight-bold" @click="home">
            <span>TOP</span>
          </a>
          <div class="line-left"></div>
          <a class="navbar-item has-text-weight-bold" @click="classroom">教室管理</a>
          <div class="line-left"></div>
          <a class="navbar-item has-text-weight-bold" @click="account">アカウント管理</a>
          <div class="line-left"></div>
          <a class="navbar-item has-text-weight-bold" @click="book">教材情報設定</a>
          <div class="line-left"></div>
          <a class="navbar-item has-text-weight-bold" @click="tag">タグ管理</a>
        </div>

        <div class="navbar-end">
          <div>
            <button class="button is-rounded is-light" @click="logout">
              <span class="icon is-small">
                <fa icon="sign-out-alt" />
              </span>
              <span>ログアウト</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>


<script>
export default {
  name: "Navbar",
  data() {
    return {
      navbar_display: true,
      data: 0
    };
  },
  methods: {
    home: function() {
      this.$router.push({ name: "SystemTop" });
    },
    classroom: function() {
      this.$router.push({ name: "SystemClassroom" });
    },
    account: function() {
      this.$router.push({ name: "SystemAccount" });
    },
    book: function() {
      this.$router.push({ name: "BookList" });
    },
    tag: function() {
      this.$router.push({ name: "TagList" });
    },
    logout: function() {
      this.$router.push({ name: "Login" });
    }
  }
};
</script>