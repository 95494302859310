<template>
  <div>
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('SystemTop')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">教室管理</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">教室管理</h1>
        </div>
        <div class="column">
          <button class="button is-primary is-rounded registration-btn" @click="classroom_reg">
            <span>教室登録</span>
          </button>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column"></div>
        <div class="column is-10">
          <div class="teacher-tbl">
            <table class="table is-fullwidth">
              <tr>
                <th>教室ID</th>
                <th>教室名</th>
                <th>操作</th>
              </tr>
              <tr v-for="(item,index) in classroom_list" :key="item" @click="open_classroom_modal(item)">
                <td>{{ index + 1 }}</td>
                <td>{{ item }}</td>
                <td>
                  <button class="ml-3 button is-info tb-btn" @click="open_admin_modal(teacher)">編集</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="column"></div>
      </div>
      <div
        class="classroom-modal modal"
        v-bind:class="{ 'is-active': modal_isActive }"
      >
        <div class="modal-background" @click="close_classroom_modal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button
              class="delete"
              aria-label="close"
              @click="close_classroom_modal"
            ></button>
          </header>
          <section class="modal-card-body">
            <p class="title is-4">登録する教室名を入力して下さい</p>
            <br />
            <div class="classroom-tb">
              <table class="table is-fullwidth">
                <tr>
                  <td>
                    <p class="subtitle">教室名</p>
                  </td>
                  <td>
                    <input class="input is-medium" type="text" />
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-primary is-rounded registration-btn is-medium"
              @click="close_classroom_modal"
            >
              <span>登録</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      isActive: false,
      modal_isActive: false,
      classroom_list: [],
    };
  },
  mounted() {
    this.classroom_list = this.$store.state.classroom_master;
  },
  methods: {
    classroom_reg: function () {
      this.modal_isActive = true;
      console.log("classroom_reg");
    },
    open_classroom_modal: function (inval) {
      console.log(inval);
    },
    close_classroom_modal: function () {
      this.modal_isActive = false;
    },
  },
};
</script>
