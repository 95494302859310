<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">生徒一覧</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">生徒一覧</h1>
        </div>
        <div class="column">
          <button class="button is-primary is-rounded registration-btn" @click="create_student">
            <span>新規登録</span>
          </button>
        </div>
        <div class="column is-1"></div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <table class="table tb-search">
            <tr>
              <td class="tb-middle subtitle is-5">生徒ID:</td>
              <td class="tb-middle s-input">
                <input class="input" type="text" placeholder="" v-model="student_id" @keydown.enter="local_serch"/>
              </td>
            </tr>
            <tr>
              <td class="tb-middle subtitle is-5">生徒名:</td>
              <td class="tb-middle s-input">
                <input class="input" type="text" placeholder="" v-model="student_name" @keydown.enter="local_serch"/>
              </td>
              <td>
                <button class="button is-info is-rounded tb-btn" @click="local_serch">検索</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br>
      <div class="columns">
        <div class="column">
          <div class="tb-std">
            <table class="table is-fullwidth">
              <tr>
                <th>No</th>
                <th>生徒ID</th>
                <th>生徒名</th>
                <th>学年</th>
                <th>教室</th>
                <th>管理</th>
              </tr>
              <tr v-for="(student, index) in student_list" :key="student">
                <td>{{ index + 1 }}</td>
                <td>{{ student.student_id }}</td>
                <td>{{ student.student_name }}</td>
                <td>{{ student.grade }}</td>
                <td>{{ get_classroom_name(student.classroom_id) }}</td>
                <td>
                  <button class="button is-primary tb-btn" @click="change_target_student(student)">学習の記録をする</button>
                  <button class="ml-3 button is-info tb-btn" @click="view_student_detail(student)">詳細</button>
                </td>
                <!--変更確認のモーダル-->
                <div class="classroom-modal modal" v-bind:class="{ 'is-active': modal_is_active }">
                  <div class="modal-background" @click="modal_close"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title"></p>
                      <button class="delete" aria-label="close" @click="modal_close"></button>
                    </header>
                    <section class="modal-card-body modal-title">
                      <span class="subtitle is-4">対象の生徒を変更しますがよろしいですか？</span>
                    </section>
                    <footer class="modal-card-foot modal-btn">
                      <button class="button" @click="modal_close">キャンセル</button>
                      <button class="button is-success" @click="change_student_confirm()">確定</button>
                    </footer>
                  </div>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      student_list: [],
      target_student_info: {},
      student_id:'',
      student_name:'',
      modal_is_active: false
    };
  },
  mounted: function () {
    this.get_student_list();
  },
  computed: {
    get_classroom_name() {
      return (classroom_id) => {
        return this.$store.state.classroom_master[classroom_id];
      }
    }
  },
  methods: {
    view_student_detail: function (student) {
      this.$router.push({ name: "StudentEdit", query: { "student_id": student.student_id } });
    },
    change_target_student: function (student) {
      this.modal_is_active = true;
      this.target_student_info = student;
    },
    change_student_confirm: function () {
      this.$store.commit('setStudentInfo', this.target_student_info)
      this.modal_is_active = false;
      this.$router.push({ name: "StudentDetail" });
    },
    get_student_list: function () {
      let jsonbody = {};
      jsonbody.login_info = {};
      jsonbody.login_info.token = localStorage.AccessToken;
      jsonbody.student = {};
      this.axios
        .post(this.$store.state.url + "student", jsonbody, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          let all_Student_list = res.data;

          //Todo 現在のクラスのユーザだけに絞る
          for (let i = 0; i < all_Student_list.length; i++) {
            if (all_Student_list[i].classroom_id == this.$store.state.selected_classroom) {
              console.log(all_Student_list[i])
            }
          }

          this.student_list = res.data;
          this.all_student_list = res.data;
        })
        .catch((err) => {
          console.log(err.response.data.detail);
          alert(err.response.data.detail)
        });
    },
    local_serch(){
      var serched_users = [];
      this.student_list = this.all_student_list;

      for(var lsit in this.student_list) {
        var student = this.student_list[lsit];
        if(student.student_id.indexOf(this.student_id) !== -1) {
          if(student.student_name.indexOf(this.student_name) !== -1) {
            serched_users.push(student);
          }
        }
      }
      this.student_list = serched_users;
    },
    modal_close:function(){
      this.modal_is_active = false;
      this.target_student_info={};
    },
    create_student: function () {
      this.$router.push({ name: "StudentCreate" });
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    }
  }
};
</script>
