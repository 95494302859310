<template>
  <div>
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('BookList')">教材一覧</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">教材登録</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {{ book }}
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">教材登録</h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-10">
          <table class="table is-fullwidth bookreg-field-table">
            <tr>
              <td class="title is-6">書籍ID</td>
              <td class="has-text-left">{{ book_id }}</td>
            </tr>
            <tr>
              <td class="title is-6">教材名</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="title"/>
                </div>
              </td>
            </tr>
            <tr>
              <td class="title is-6">タグ</td>
              <td class="control has-text-left">
                <template v-for="(item, value, index) in tag_lists" :key="index">
                    <label :for="item.tag_id">
                    <input type="checkbox" :id="item.tag_id" v-model="tag_id" :value="item.tag_id" /><span>{{ item.name }}</span>
                    </label>
                </template>
              </td>
            </tr>
            <tr>
              <td class="title is-6">備考</td>
              <td>
                <div class="control">
                  <textarea class="textarea" type="textarea" v-model="other"></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <td class="title is-6">サムネイルURL</td>
              <td class="has-text-left">{{ thumnail_url }}
              </td>
              
            </tr>
            <tr>
              <td class="title is-6">サムネイル</td>
              <td class="has-text-left">
                <template v-if="thumnail_url">
                  <img :src="thumnail_url" width="96" height="65">
                </template>
              </td>
            </tr>
            <tr>
              <td class="title is-6">登録日</td>
              <td class="has-text-left">{{ created_at }}</td>
            </tr>
            <tr>
              <td class="title is-6">更新日</td>
              <td class="has-text-left">{{ updated_at }}</td>
            </tr>
          </table>
          <br />
          <div class="columns is-centered has-text-centered">
            <div class="column is-3 learn-content">
              <button class="button is-rounded is-medium" @click="page_push('BookList')">
                戻る
              </button>
            </div>
            <div class="column is-3 learn-content">
              <button v-if="this.$route.query.type=='update'" class="button is-danger is-rounded is-medium" @click="bookdelete()">
                削除
              </button>
            </div>
            <div class="column is-3 learn-content">
              <button v-if="this.$route.query.type=='regist'" class="button is-primary is-rounded is-medium" @click="bookregist()">
                登録
              </button>
              <button v-if="this.$route.query.type=='update'" class="button is-primary is-rounded is-medium" @click="bookupdate()">
                更新
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      book_id: "",
      title: "",
      tag_id: [],
      thumnail_url:"",
      other: "",
      appendix_flg: 0,
      updated_at: "",
      created_at: "",
      book:{},
      tag_lists: [],
    };
  },
  mounted: async function () {
    this.book_id = this.$route.query.book_id;
    if (this.$route.query.type == 'update'){
      this.getbook();
    }
    await this.gettaglist();
  },
  methods: {
    getbook: function () {
      let jsonbody = {};
      jsonbody.book_id = this.book_id;
      this.axios
        .post(this.$store.state.url + "book", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.title = res.data[0].title;
          this.tag_id = res.data[0].tag_id;
          this.thumnail_url = res.data[0].thumnail_url;
          this.other = res.data[0].other;
          this.appendix_flg = res.data[0].appendix_flg;
          this.updated_at = res.data[0].updated_at;
          this.created_at = res.data[0].created_at;
          console.log(res.data);
        });
    },
    bookregist: function () {
      let jsonbody = {};
      jsonbody.book_id = this.book_id;
      jsonbody.title = this.title;
      jsonbody.tag_id = this.tag_id;
      jsonbody.thumnail_url = this.$store.state.thumbnailbaseurl + this.book_id + ".jpg";
      jsonbody.other = this.other;
      jsonbody.appendix_flg = this.appendix_flg;
      this.axios
        .post(this.$store.state.url + "book/create", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.book_lists = res.data;
          console.log(res.data);
          this.$router.push({ name: "BookList" });
        });
    },
    bookupdate: function () {
      let jsonbody = {};
      jsonbody.book_id = this.book_id;
      jsonbody.title = this.title;
      jsonbody.tag_id = this.tag_id;
      jsonbody.thumnail_url = this.$store.state.thumbnailbaseurl + this.book_id + ".jpg";
      jsonbody.other = this.other;
      jsonbody.appendix_flg = this.appendix_flg;
      this.axios
        .post(this.$store.state.url + "book/update", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.book_lists = res.data;
          console.log(res.data);
          this.$router.push({ name: "BookList" });
        });
    },
    bookdelete: function () {
      var result = confirm('教材情報を削除しますがよろしいですか？');
      if (result) {
      let jsonbody = {};
      jsonbody.book_id = this.book_id;
      this.axios
        .post(this.$store.state.url + "book/delete", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.book_lists = res.data;
          console.log(res.data);
          this.$router.push({ name: "BookList" });
        });
      } else {
        //キャンセルを押した場合
      }
    },
    gettaglist: async function () {
      let jsonbody = {};
      await this.axios
        .post(this.$store.state.url + "tag", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.tag_lists = res.data;
          //console.log(res.data);
        });
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
  },
};
</script>
<style> 
label {
    margin-right: 5px; /* ボタン同士の間隔 */
}
label input {
    display: none; /* デフォルトのinputは非表示にする */
}
label span {
    color: #333; /* 文字色を黒に */
    font-size: 0.8em; /* 文字サイズを14pxに */
    border: 1px solid #333; /* 淵の線を指定 */
    border-radius: 1.2em; /* 角丸を入れて、左右が丸いボタンにする */
    padding: 5px 20px; /* 上下左右に余白をトル */
    background: white; /* 背景色を薄い赤に */
}
label input:checked + span {
    color: #FFF; /* 文字色を白に */
    background: #485fc7; /* 背景色を薄い赤に */
    border: 1px solid #485fc7; /* 淵の線を薄い赤に */
}
</style>