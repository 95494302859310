<template>
  <div class="login" id="app">
    <div class="columns">
      <div class="column"></div>
      <div class="column is-3">
        <div v-if="NEW_PASSWORD_REQUIRED" class="login-screen">
          <div class="is-size-1 has-text-weight-bold login-top">
            <img src="../assets/mystep_lpgo.svg" />
          </div>
          <br />
          <p>新しいパスワードを設定してください</p>

          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input v-bind:class="{ 'is-danger': password_empty }" class="input" :type="inputType" placeholder="新しいパスワード" v-model="new_password" />
              <span class="icon is-small is-left">
                <fa icon="lock" />
              </span>
            </p>
            <p v-show="password_empty" class="help is-danger">パスワードを入力してください</p>
            <div class="pass-check">
              <label class="checkbox">
                <input type="checkbox" v-model="inputCheked" />
                パスワードを表示する
              </label>
            </div>
          </div>

          <button class="button is-primary" @click="password_required()" v-bind:class="{ 'is-loading': loding }">登録</button>
          <br />
        </div>
        <div v-else class="login-screen">
          <div class="is-size-1 has-text-weight-bold login-top">
            <img src="../assets/mystep_lpgo.svg" />
          </div>
          <br />
          <p v-show="this.$route.query['email']" class="txt-left">ご登録いただいたメールアドレスに仮パスワードを送信しました。</p>
          <div class="field">
            <p v-show="login_error" class="has-text-danger">※{{ login_error }}</p>
            <p class="control has-icons-left has-icons-right">
              <input v-bind:class="{ 'is-danger': email_empty }" class="input" type="text" placeholder="メールアドレス" v-model="email" />
              <span class="icon is-small is-left">
                <fa icon="envelope" />
                <i class="fas fa-envelope"></i>
              </span>
            </p>
            <p v-show="email_empty" class="help is-danger">メールアドレスを入力してください</p>
          </div>

          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input v-bind:class="{ 'is-danger': password_empty }" class="input" :type="inputType" placeholder="パスワード" v-model="password" />
              <span class="icon is-small is-left">
                <fa icon="lock" />
                <i class="fas fa-envelope"></i>
              </span>
            </p>
            <p v-show="password_empty" class="help is-danger">パスワードを入力してください</p>
            <div class="pass-check">
              <label class="checkbox">
                <input type="checkbox" v-model="inputCheked" />
                パスワードを表示する
              </label>
            </div>
          </div>

          <button class="button is-primary" @click="login(email, password)" @keydown.enter="login(email, password)" v-bind:class="{ 'is-loading': loding }">
            ログイン
          </button>
          <br />
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  //   components: {
  //     HelloWorld

  //   }
  data() {
    return {
      login_error: "",
      email_empty: false,
      password_empty: false,
      loding: false,
      email: "",
      password: "",
      new_password: "",
      session: "",
      NEW_PASSWORD_REQUIRED: false,
      inputCheked: false, // パスワード表示・非表示
    };
  },
  methods: {
    login: async function (email, password) {
      this.loding = true;
      this.email_empty = false;
      this.password_empty = false;

      if (email == "") {
        this.email_empty = true;
        this.loding = false;
      }
      if (password == "") {
        this.password_empty = true;
        this.loding = false;
      }

      if (!this.email_empty && !this.password_empty) {
        this.axios
          .post(
            this.$store.state.url + "login",
            {
              username: email,
              password: password,
            },
            {
              accept: "application/json",
              "Content-Type": "application/json",
            }
          )
          .then(async (res) => {
            console.log(res)
            if ("ChallengeName" in res.data[0]) {
              if (res.data[0]["ChallengeName"] == "NEW_PASSWORD_REQUIRED") {
                this.NEW_PASSWORD_REQUIRED = true;
                this.inputCheked = false;
                this.new_password = "";
                this.session = res.data[0]["Session"];
                this.loding = false;
                // alert('NEW_PASSWORD_REQUIRED : ' + this.NEW_PASSWORD_REQUIRED);
              }
            } else {
              var token = res.data[0].AuthenticationResult.AccessToken;
              localStorage.AccessToken = token;

              //ユーザのログイン情報をstoreに保存
              this.$store.commit("setMyInfo", res.data[1]);
              this.$store.commit("setNowClassRoom", res.data[1]["custom_group"][0]);
              await this.get_classroom_master();

              if (res.data[1]["custom_authority_group"] == "admin") {
                this.$router.push({ name: "SystemTop" });
              } else if (res.data[1]["custom_authority_group"] == "teacher") {
                this.$router.push({ name: "Top" });
              }
            }
          })
          .catch((err) => {
            this.error_check = true;
            this.loding = false;
            if(err.response && 'data' in err.response){
              this.login_error = err.response.data.detail;
            } else {
              this.login_error = "システムエラーが発生しました。"
            }
            
          });
      }
    },
    password_required: async function () {
      this.loding = true;
      this.password_empty = false;

      if (this.new_password == "") {
        this.password_empty = true;
        this.loding = false;
      } else {
        this.axios
          .post(
            this.$store.state.url + "new_password",
            {
              username: this.email,
              password: this.new_password,
              session: this.session,
            },
            {
              accept: "application/json",
              "Content-Type": "application/json",
            }
          )
          .then(async (res) => {
            var token = res.data[0].AuthenticationResult.AccessToken;
            localStorage.AccessToken = token;

              //ユーザのログイン情報をstoreに保存
              this.$store.commit("setMyInfo", res.data[1]);
              this.$store.commit("setNowClassRoom", res.data[1]["custom_group"][0]);
              await this.get_classroom_master();

            if (res.data[1]["custom_authority_group"] == "admin") {
              this.$router.push({ name: "SystemTop" });
            } else if (res.data[1]["custom_authority_group"] == "teacher") {
              this.$router.push({ name: "Top" });
            }
          })
          .catch((err) => {
            console.log(err.response.data.detail);
            this.login_error = err.response.data.detail;
            this.error_check = true;
            this.loding = false;
          });
      }
    },
    create_user: function () {
      this.$router.push({ name: "CreateUser" });
    },
    passInput: function () {
      this.inputCheked = !this.inputCheked;
      console.log(this.inputCheked);
    },
    get_classroom_master: async function () {
      let jsonbody = {};
      await this.axios.post(this.$store.state.url + "classroom", jsonbody, this.$store.state.post_json).then((res) => {
        var tmpjson = {};
        //扱いやすい状態にして入れる
        for (let i = 0; i < res.data.length; i++) {
          var classroom_id = res.data[i]["classroom_id"];
          var classroom = res.data[i]["classroom"];
          tmpjson[classroom_id] = classroom;
        }
        this.$store.commit("setClassRoomMaster", tmpjson);
      });
    },
    //tokenを投げてユーザ情報をstoreに格納する。
    //現状使い道はない
    set_my_info: async function (token) {  
      await this.axios
        .post(this.$store.state.url + "cognito_auth", { token: token }, this.$store.state.post_url)
        .then((res) => {
          var tmpjson = {};
          //扱いやすい状態にして入れる
          for (let i = 0; i < res.data.UserAttributes.length; i++) {
            var key = res.data.UserAttributes[i]["Name"];
            var value = res.data.UserAttributes[i]["Value"];

            if (key == "custom:authority_group") {
              tmpjson["custom_authority_group"] = value;
            } else if (key == "custom:group") {
              //文字列エスケープされた配列が来る
              var joined_group = JSON.parse(value);
              tmpjson["custom_group"] = joined_group;
              this.$store.commit("setNowClassRoom", joined_group[0]);
            } else {
              tmpjson[key] = value;
            }
          }
          console.log("setMyInfo")   
          this.$store.commit("setMyInfo", tmpjson);
        })
        .catch((err) => {
          console.log(err.response.data.detail);
          localStorage.removeItem("AccessToken");
          this.$router.push({ name: "Login" });
        });
    },
  },
  computed: {
    inputType: function () {
      return this.inputCheked ? "text" : "password";
    },
  },
};
</script>
