<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('StudentList')">生徒一覧</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">生徒編集</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">
            生徒編集
          </h1>
        </div>
      </div>
      <br />

      <div class="columns">
        <div class="column is-10">
          <table class="reg-field-table table is-fullwidth">
            <tr>
              <td class="title is-6">生徒ID</td>
              <td class="has-text-left">{{ student_info.student_id }}</td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">氏名</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="student_info.student_name" />
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">フリガナ</td>
              <td class="control">
                <input class="input" type="text" v-model="student_info.student_name_kana" />
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">学年</td>
              <td>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="student_info.grade">
                      <option v-for="(grade) in this.grades" :key="grade">{{ grade }}</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>

            <br />
            <tr>
              <td class="title is-6">教室</td>
              <td>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="student_info.classroom_id">
                      <option v-for="(classroom) in selectable_classroom" :key="classroom" :value="Object.keys(classroom)[0]">{{ Object.values(classroom)[0] }}</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">備考</td>
              <td>
                <div class="control">
                  <textarea class="textarea" type="textarea" v-model="student_info.other"></textarea>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <div class="columns is-centered has-text-centered">
        <div class="column is-3 learn-content">
          <button class="button is-rounded is-medium" @click="page_push('StudentList')">
            戻る
          </button>
        </div>
        <div class="column is-3 learn-content">
          <button class="button is-danger is-rounded is-medium" @click="student_delete">
            削除
          </button>
        </div>
        <div class="column is-3 learn-content">
          <button class="button is-primary is-rounded is-medium" @click="student_update">
            更新
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      grades: [
        "小学1年",
        "小学2年",
        "小学3年",
        "小学4年",
        "小学5年",
        "小学6年",
      ],
      selectable_classroom: {},
      student_info: {}
    };
  },
  mounted: function () {

    this.get_student_list();

    //自分の所属している教室のみを抽出
    var tmparray = [];

    for (let i = 0; i < this.$store.state.my_info.custom_group.length; i++) {
      var tmpjson = {};
      var classroom_id = this.$store.state.my_info.custom_group[i];
      var value = this.$store.state.classroom_master[classroom_id];
      tmpjson[classroom_id] = value;
      tmparray.push(tmpjson);
    }

    this.selectable_classroom = tmparray;

  },
  methods: {
    student_update: function () {
      let jsonbody = this.student_info;

      this.axios
        .post(this.$store.state.url + "student/update", jsonbody, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          this.$router.push({ name: "StudentList" });
        });
    },
    student_delete: function () {
      var result = confirm('ユーザを削除しますがよろしいですか？');
      if (result) {
        let jsonbody = {};
        jsonbody.student_id = this.student_info.student_id;

        this.axios
          .post(this.$store.state.url + "student/delete", jsonbody, this.$store.state.post_json)
          .then((res) => {
            console.log(res.data);
            this.$router.push({ name: "StudentList" });
          });
      } else {
        //キャンセルを押した場合
      }

    },
    get_student_list: function () {
      let jsonbody = {};
      jsonbody.login_info = {};
      jsonbody.login_info.token = localStorage.AccessToken;
      jsonbody.student = {};
      jsonbody.student.student_id = this.$route.query.student_id;
      this.axios
        .post(this.$store.state.url + "student",jsonbody,this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          this.student_info = res.data[0];
        })
        .catch((err) => {
            console.log(err.response.data.detail);
            alert(err.response.data.detail)
        });
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
  },
};
</script>
