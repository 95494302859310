<template>
  <div class="system-top">
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">システム管理者画面TOP</h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <h1 class="title">お知らせ</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column is-10">
          <div class="card">
            <div class="card-content news">
              <div class="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  methods: {
    login: function() {
      this.$router.push({ name: "Top" });
    }
  }
};
</script>
