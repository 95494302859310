<template>
  <div>
    <div id="nav">
    </div>
    <router-view />
  </div>
</template>

<style lang="scss">
/* eslint-disable */
@import "./node_modules/bulma/bulma.sass";
@import "./assets/scss/index.scss";
</style>