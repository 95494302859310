<template>
  <div id="app">
    <div class="columns">
      <div class="column"></div>
      <div class="column is-3">
        <div class="login-screen">
          <!-- <div class="is-size-1 has-text-weight-bold login-top">
              <img src="../assets/mystep_lpgo.svg" />
            </div> -->
          <br />
          <p class="title is-1">新規登録</p>
          <div class="field">
            <p class="control has-icons-left has-icons-right">
              <input
                v-bind:class="{ 'is-danger': name_empty }"
                class="input"
                type="text"
                placeholder="名前"
                v-model="name"
              />
              <span class="icon is-small is-left">
                <fa icon="user" />
                <i class="fas fa-envelope"></i>
              </span>
            </p>
            <p v-show="name_empty" class="help is-danger">
              名前を入力してください
            </p>
          </div>
          <div class="field">
            <p v-show="login_error" class="has-text-danger">
              ※{{ login_error }}
            </p>
            <p class="control has-icons-left has-icons-right">
              <input
                v-bind:class="{ 'is-danger': email_empty }"
                class="input"
                type="text"
                placeholder="メールアドレス"
                v-model="email"
              />
              <span class="icon is-small is-left">
                <fa icon="envelope" />
                <i class="fas fa-envelope"></i>
              </span>
            </p>
            <p v-show="email_empty" class="help is-danger">
              メールアドレスを入力してください
            </p>
          </div>

          <button
            class="button is-primary"
            @click="create()"
            v-bind:class="{ 'is-loading': loding }"
          >
            アカウントを新規登録
          </button>
        </div>
      </div>
      <div class="column"></div>
    </div>
  </div>
</template>
  
  <script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "app",
  data() {
    return {
      has_error: false,
      login_error: "",
      email_empty: false,
      name_empty: false,
      email: "",
      name: "",
      loding: false,
    };
  },
  methods: {
    create: function () {
      this.loding = true;
      this.has_error = false;
      this.email_empty = false;
      this.name_empty = false;

      console.log(this.email);
      console.log(this.name);

      if (this.email == "") {
        this.email_empty = true;
        this.has_error = true;
        this.loding = false;
      }
      if (this.name == "") {
        this.name_empty = true;
        this.has_error = true;
        this.loding = false;
      }

      if (!this.has_error) {
        let jsonbody = {};
        jsonbody.email = this.email;
        jsonbody.username = this.name;
        jsonbody.authority_group = "teacher";

        this.axios
          .post(this.$store.state.url + "createadminuser", jsonbody)
          .then((res) => {
            console.log(res.data);
            this.$router.push({ name: "Login", query: { email: true } });
          })
          .catch((err) => {
            console.log(err.response.data);
            this.login_error = err.response.data;
            this.has_error = true;
            this.loding = false;
          });
      }
    },
  },
};
</script>
  