<template>
  <div>
    <Navbar />
    <div class="columns is-centered">
      <div class="column is-11">
        <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
          <ul>
            <li><a @click="page_push('Top')">Top</a></li>
            <li><a @click="page_push('StudentDetail')">生徒詳細</a></li>
            <li><a @click="page_push('TextbookList')">教材選択</a></li>
            <li><a @click="page_push('PageSelect')">ページ選択</a></li>
            <li class="is-active">
              <a href="#" aria-current="page">PDF</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <br />
    <div class="columns is-centered" v-show="his_add_active">
      <div class="column is-11">
        <div class="notification is-primary is-light">
          <button class="delete" @click="active_delete"></button>
          履歴に追加しました。<strong><a @click="history_detail">履歴一覧</a></strong>をご確認ください。
        </div>
      </div>
    </div>
    <br />
    <div class="columns is-centered">
      <div class="column is-11">
        <div class="columns">
          <div class="column is-8 pdf_frame">
            <span v-if="pdf_src == ''" >データ取得中です<progress class="progress is-large is-info" max="100">60%</progress></span>
            <span v-else><iframe :src="pdf_src"></iframe></span>
          </div>
          <div class="column">
            <div class="pdf_content">
              <div class="t-a-r">
                <button class="button is-primary is-rounded is-medium tb-btn" @click="history_add" v-bind:class="{ 'is-loading': loding }" v-bind:disabled="his_btn_disabled">
                  今日の履歴に追加する
                </button>
              </div>
              <br />
              <p class="title">解答</p>
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <ul v-for="(pdf, index) in appendix" :key="pdf">
                      <li type="sqare">
                        <a :href="pdf" target="_blank">{{ index }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <br />
              <p class="title">類題</p>
              <div class="card">
                <div class="card-content">
                  <ul v-for="book in books" :key="book">
                    <button class="page-btn button is-inverted" @click="page_pdf(book.book_id, book.page)">
                      {{ book.book_title }} : {{ book.page }} ページ
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="classroom-modal modal" v-bind:class="{ 'is-active': modal_is_page }">
      <div class="modal-background" @click="page_modal_close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"></p>
          <button class="delete" aria-label="close" @click="page_modal_close"></button>
        </header>
        <section class="modal-card-body modal-title">
          <span class="subtitle is-4">
            現在、選択しているページは履歴登録されていません
          </span>
          <p>
            <a :href="pdf_file" target="_blank">選択したページを確認する</a>
          </p>
        </section>
        <footer class="modal-card-foot modal-btn">
          <button class="button" @click="page_modal_close">キャンセル</button>
          <button class="button is-success" @click="page_modal_move" v-bind:class="{ 'is-loading': loding }">
            登録して選択画面に移動
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      pdf_src:"",
      select_pages: [],
      appendix: {},
      books: {},
      loding: false,
      his_add_active: false,
      his_btn_disabled: false,
      page_tags: [],
      modal_is_page: false,
      bookid: "", // 類似した問題の書籍ID
      page: [], // 類似した問題のページ
      pdf_file: "",
      book_info: {},
      history_id:'',
      student_id:''
    };
  },
  mounted: function () {
    this.book_info = this.$store.state.selected_book_info;

    this.do_margepdf();
    this.do_getappendix();
    this.get_page_detail();
    this.get_page_tag();
  },
  methods: {
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    history_detail: function () {
      this.$router.push({ name: "LearnHisDetail" , query: { "student_id": this.student_id ,"history_id": this.history_id } });
    },
    //選択されたページのPDFを取得
    do_margepdf: function () {
      let jsonbody = {};
      jsonbody.bookid = this.book_info.bookid;
      jsonbody.pages = this.book_info.pages;
      this.axios
        .post(this.$store.state.url + "margepdf", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.pdf_src = res.data;
          console.log(res.data)
        });
    },
    //選択された書籍の付録類を取得
    do_getappendix: function () {
      let jsonbody = {};
      jsonbody.bookid = this.book_info.bookid;

      this.axios
        .post(this.$store.state.url + "getappendix", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.appendix = res.data;
        });
    },
    //該当ページについているタグ一覧を取得する
    get_page_detail: function () {

      let jsonbody = {};
      //jsonbody.book_id = this.book_info.bookid;
      jsonbody.book_id = "book001";
      jsonbody.page = this.book_info.pages;

      let similar_tag_pages = [];
      this.axios
        .post(
          this.$store.state.url + "page",jsonbody,this.$store.state.post_json)
        .then((res) => {

          res.data.forEach((page) => {
            page.tag_id.forEach((tag) => {
              if (!similar_tag_pages.includes(Number(tag))) {
                similar_tag_pages.push(Number(tag));
              }
            });
          });
        });
      console.log("page_tags", similar_tag_pages);
    },
    //タグに紐づくページ一覧(類題)を取得する
    get_page_tag: function () {

      let jsonbody = {};
      jsonbody.tag = this.book_info.pages;

      this.axios
        .post(this.$store.state.url + "page_tag",jsonbody,this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          this.books = res.data;
        });
    },

    history_add: function () {
      this.loding = true;

      let selected_book_info = this.$store.state.selected_book_info;
      let history_detail = { detail: [] };
      if (selected_book_info.pages.length) {
        selected_book_info.pages.forEach((page) => {
          history_detail.detail.push({
            book_id: selected_book_info.book_id,
            subject: "",
            code: [],
            page: page,
            do: "",
            content: selected_book_info.title,
            memo: "",
            HW: "",
            list_order: history_detail.detail.length + 1,
          });
        });
      }

      let jsonbody = {};
      jsonbody.student_id = this.$store.state.student_info.student_id;
      jsonbody.learning_detail = history_detail;

      this.axios
        .post(
          this.$store.state.url + "history/add",
          jsonbody,
          this.$store.state.post_json
        )
        .then((res) => {
          console.log(res.data);
          this.history_id = res.data[0].history_id;
          this.student_id = res.data[0].student_id;
          this.his_btn_disabled = true;
          this.his_add_active = true;
          this.loding = false;
        })
        .catch((err) => {
          console.log(err.response.data.detail);
          this.loding = false;
        });
    },
    active_delete: function () {
      this.his_add_active = false;
    },
    page_pdf: function (book_id, page) {
      if (this.his_btn_disabled) {
        // 履歴の登録がされているか確認する
        let bookid = book_id;
        let pages = [];
        pages.push(page);

        // 仮代入
        bookid = "ele_basetraining_dokkai_1";

        this.$store.commit("setBookInfo", {
          book_id: bookid,
          pages: pages,
        });

        this.$router.push({ name: "PageSelect" });
      } else {
        // 登録がない場合にモーダル表示する
        this.modal_is_page = true;
        this.bookid = book_id;
        this.page = [];
        this.page.push(page);

        // 仮代入
        this.bookid = "ele_basetraining_dokkai_1";

        // 選択したページをpdfファイルに
        let jsonbody = {};
        jsonbody.bookid = this.bookid;
        jsonbody.pages = this.page;
        this.axios
          .post(
            this.$store.state.url + "margepdf",
            jsonbody,
            this.$store.state.post_json
          )
          .then((res) => {
            this.pdf_file = res.data;
          });
      }
    },
    page_modal_close: function () {
      this.modal_is_page = false;
    },
    page_modal_move: function () {
      this.history_add();
      this.$router.push({ name: "PageSelect" });
    },
  },
};
</script>
