<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">教材詳細</h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column is-10">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column is-2">
                    <span class="icon i-user">
                      <fa icon="user" class="size" />
                    </span>
                  </div>
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <div class="subtitle subtitle is-4">生徒001さん</div>
                        <div class="subtitle subtitle is-5">XX教室 小学3年</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">{{"漢字ドリル"}}</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <div class="tags">
            <span class="tag is-medium is-light">tag</span>
            <span class="tag is-medium is-light">tag</span>
            <span class="tag is-medium is-light">tag</span>
            <span class="tag is-medium is-light">tag</span>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="tb-list">
            <table class="table is-fullwidth">
              <tr>
                <th>目次</th>
                <th>最終実施日</th>
                <th>コメント</th>
              </tr>
              <tr v-for="content in std_book_detail" :key="content">
                <td>{{content.page}}</td>
                <td>{{content.date}}</td>
                <td>{{content.comment}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      std_book_detail: [
        { page: "第1章", date: "2022/10/4", comment: "がんばってました。" },
        { page: "第2章", date: "2022/10/5", comment: "がんばってました。" },
        { page: "第3章", date: "2022/10/6", comment: "がんばってました。" },
        { page: "第4章", date: "2022/10/7", comment: "がんばってました。" }
      ]
    };
  },
  methods: {
    login: function() {
      this.$router.push({ name: "Top" });
    },
    student_edit: function(){
      this.$router.push({ name: "StudentReg", query:{student_id: this.$route.query['student_id']} })
    },
  }
};
</script>
