<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">
            生徒新規登録
          </h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-10">
          <table class="reg-field-table table is-fullwidth">
            <tr>
              <td class="title is-6">生徒ID</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="student_id" />
                </div>
              </td>
            </tr>
            <tr>
              <td class="title is-6">氏名</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="student_name" />
                </div>
              </td>
            </tr>
            <br />
            <!--<tr>
              <td class="title is-6">フリガナ</td>
              <td class="control">
                <input class="input" type="text" v-model="student_name_kana" />
              </td>
            </tr>-->
            <br />
            <tr>
              <td class="title is-6">学年</td>
              <td>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select>
                      <option v-for="grade in grades" :key="grade">
                        {{ grade }}
                      </option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>

            <br />
            <tr>
              <td class="title is-6">教室</td>
              <td>
                <div class="control">
                    <input class="input" type="text" v-model="view_classroom" />
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">備考</td>
              <td>
                <div class="control">
                  <textarea class="textarea" type="textarea" v-model="other"></textarea>
                </div>
              </td>
            </tr>
          </table>
          <br />
          <div class="text-right">
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button class="button is-dark is-rounded is-medium" @click="student_regist">
                      登録
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      grades: [
        "小学1年",
        "小学2年",
        "小学3年",
        "小学4年",
        "小学5年",
        "小学6年",
      ],
      student_id: "",
      student_name: "",
      student_name_kana: "",
      grade: "",
      classroom_id: "",
      other: "",
      view_classroom:""
    };
  },
  mounted: function () {
    this.classroom_id = this.$store.state.selected_classroom;
    this.view_classroom = this.$store.state.classroom_master[this.classroom_id];
  },
  methods: {
    student_regist: function () {

      //paramチェック
      if (!this.check_validation()){
        alert("生徒ID,氏名は登録必須です")
        return false
      }

      let jsonbody = {};
      jsonbody.student_id = this.student_id
      jsonbody.student_name = this.student_name
      jsonbody.student_name_kana = this.student_name_kana
      jsonbody.grade = this.grade
      jsonbody.other = this.other
      jsonbody.classroom_id = this.classroom_id;

      this.axios
        .post(
          this.$store.state.url + "student/create",
          jsonbody,
          this.$store.state.post_json
        )
        .then((res) => {
          console.log(res.data);
          alert("生徒を登録しました。")
          this.$router.push({ name: "StudentList" });
        })
        .catch((err) => {
            console.log(err.response.data.detail);
            alert(err.response.data.detail)
        });
    },
    check_validation: function () {
      if (this.student_id && this.student_name){
        return true
      }else{
        return false
      }
    }
  },
};
</script>
