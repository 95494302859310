// import { createStore } from 'vuex'
// import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  state: {
    //LambdaサーバーURL
    url: 'https://r3gnjlrd4m.execute-api.ap-northeast-1.amazonaws.com/dev/mystep/',
    //url: 'http://127.0.0.1:8000/mystep/',
    APIurl: 'https://uehuutt706.execute-api.ap-northeast-1.amazonaws.com/prod/',
    thumbnailbaseurl: 'https://zo-officialdata-www.s3.ap-northeast-1.amazonaws.com/mystep/thumbnails/',
    post_json: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    my_info: {},
    student_info: {},
    selected_book_info: {},
    classroom_master: {},
    selected_classroom: ''
  },
  mutations: {
    setMyInfo(state, val) {
      state.my_info = val
    },
    setStudentInfo(state, val) {
      state.student_info = val
    },
    setBookInfo(state, val) {
      state.selected_book_info = val
    },
    setClassRoomMaster(state, val) {
      state.classroom_master = val
    },
    setNowClassRoom(state, val) {
      state.selected_classroom = val
    }
  },
  plugins: [
    createPersistedState({
      key: 'mystep',
      paths: [
        'my_info',
        'student_info',
        'selected_book_info',
        'classroom_master',
        'selected_classroom'
      ],
      storage: window.localStrage,
    })]
})
