<template>
  <div>
    <NavbarSystem />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('SystemTop')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">アカウント管理</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">アカウント管理</h1>
        </div>
        <div class="column">
          <button class="button is-primary is-rounded registration-btn" @click="open_admin_modal('new')">
            <span>新規登録</span>
          </button>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column">
          <div class="teacher-tbl">
            <table class="table is-fullwidth">
              <tr>
                <th>No</th>
                <th>管理者名</th>
                <th>メールアドレス</th>
                <th>ステータス</th>
                <th>アカウント区分</th>
                <th>操作</th>
              </tr>
              <tr v-for="(teacher, index) in teacher_list" :key="teacher">
                <td class="has-text-right">{{ index + 1 }}</td>
                <td class="has-text-right">{{ teacher.name }}</td>
                <td class="has-text-right">{{ teacher.email }}</td>
                <td class="has-text-right">{{ changeStatusToName(teacher.UserStatus) }}</td>
                <td class="has-text-right">
                  {{ changeDivisionToName(teacher.custom_authority_group) }}
                </td>
                <td>
                  <button class="ml-3 button is-info tb-btn" @click="open_admin_modal(teacher)">編集</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <div class="classroom-modal modal" v-bind:class="{ 'is-active': create_modal_is_open }">
        <div class="modal-background" @click="close_admin_modal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="close_admin_modal"></button>
          </header>
          <section class="modal-card-body">
            <p class="title is-4">登録する教室を選択して下さい</p>
            {{ classroom_list }}
            {{ create_admin_classroom_list }}
            <br />
            <div class="classroom-tb">
              <table class="table is-fullwidth">
                <tr>
                  <td class="has-text-right">管理者名</td>
                  <td class="has-text-left"><input class="input" type="text" placeholder="管理者名" v-model="create_admin_name" /></td>
                </tr>
                <tr>
                  <td class="has-text-right">メールアドレス</td>
                  <td class="has-text-left"><input class="input" type="text" placeholder="メールアドレス" v-model="create_admin_email" /></td>
                </tr>
                <tr>
                  <td class="has-text-right">教室名</td>
                  <td class="has-text-left">
                    <template v-for="(item, value, index) in classroom_list" :key="index">
                      <p>
                        <input type="checkbox" :id="item" v-model="create_admin_classroom_list" :value="value" />
                        <label for="list.classroom_id">{{ item }}</label>
                      </p>
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-dark is-rounded registration-btn is-medium" @click="admin_regist">
              <span>登録</span>
            </button>
          </footer>
        </div>
      </div>



      <div class="classroom-modal modal" v-bind:class="{ 'is-active': edit_modal_is_open }">
        <div class="modal-background" @click="close_admin_modal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="close_admin_modal"></button>
          </header>
          <section class="modal-card-body">
            <p class="title is-4">登録する教室を選択して下さい</p>
            {{ classroom_list }}
            <br>{{ selected_teacher.custom_group }}
            <br />
            <div class="classroom-tb">
              <table class="table is-fullwidth">
                <tr>
                  <td class="has-text-right">管理者名</td>
                  <td class="has-text-left">{{ selected_teacher.name }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">メールアドレス</td>
                  <td class="has-text-left">{{ selected_teacher.email }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">教室名</td>
                  <td class="has-text-left">
                    <template v-for="(item, value, index) in classroom_list" :key="index">
                      <p>
                        <input type="checkbox" :id="item" v-model="selected_teacher.custom_group" :value="value" />
                        <label for="list.classroom_id">{{ item }}</label>
                      </p>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="has-text-right">アカウント区分</td>
                  <td class="has-text-left">
                    {{ changeDivisionToName(selected_teacher.custom_authority_group) }}
                  </td>
                </tr>
                <tr>
                  <td class="has-text-right">管理用ID</td>
                  <td class="has-text-left">{{ selected_teacher.sub }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">アカウントステータス</td>
                  <td class="has-text-left">{{ changeStatusToName(selected_teacher.UserStatus) }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">有効/無効</td>
                  <td class="has-text-left">{{ selected_teacher.Enabled }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">登録日</td>
                  <td class="has-text-left">{{ formatDateString(selected_teacher.UserCreateDate) }}</td>
                </tr>
                <tr>
                  <td class="has-text-right">更新日</td>
                  <td class="has-text-left">{{ formatDateString(selected_teacher.UserLastModifiedDate) }}</td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-rounded is-medium" @click="close_admin_modal">
              <span>戻る</span>
            </button>
            <button class="button is-danger is-rounded registration-btn is-medium">
              <span>削除</span>
            </button>
            <button class="button is-primary is-rounded registration-btn is-medium" @click="admin_edit">
              <span>更新</span>
            </button>
          </footer>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      edit_modal_is_open: false,
      create_modal_is_open: false,
      teacher_list: [],
      classroom_list: [],
      selected_teacher: {},
      create_admin_name: "",
      create_admin_email: "",
      create_admin_classroom_list: []
    };
  },
  mounted() {
    this.get_admin_list();
    this.classroom_list = this.$store.state.classroom_master;
  },
  methods: {
    get_admin_list: function () {
      let jsonbody = {};
      this.axios
        .post(this.$store.state.url + "adminlist", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.teacher_list = res.data;
          console.log(res.data);
        });
    },
    open_admin_modal: function (teacher) {
      if (teacher == 'new') {
        this.create_modal_is_open = true;
        console.log("new")
      } else {
        this.selected_teacher = teacher;
        this.edit_modal_is_open = true;
        console.log("edit")
      }
    },
    close_admin_modal: function () {
      this.edit_modal_is_open = false;
      this.create_modal_is_open = false;
    },
    admin_edit: function () {
      let jsonbody = {};
      jsonbody.sub = this.selected_teacher.sub;
      jsonbody.custom_group = this.selected_teacher.custom_group;
      this.axios
        .post(this.$store.state.url + "updateadmingroup", jsonbody, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
        });
      this.selected_teacher = {};
      this.edit_modal_is_open = false;
    },
    admin_regist: function () {
      //paramチェック
      if (!this.check_validation()) {
        alert("管理者名,管理者メールアドレスは登録必須です")
        return false
      }

      let jsonbody = {};
      jsonbody.email = this.create_admin_email;
      jsonbody.username = this.create_admin_name;
      jsonbody.authority_group = "teacher";
      jsonbody.custom_group = this.create_admin_classroom_list;

      this.axios
        .post(this.$store.state.url + "createadminuser", jsonbody)
        .then((res) => {
          console.log(res);
          if (res.data == "FORCE_CHANGE_PASSWORD") {
            this.$router.push({ name: "SystemAccount" });
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err.response);
          alert(err.response.data)
        });
    },
    check_validation: function () {
      if (this.create_admin_name && this.create_admin_email) {
        return true
      } else {
        return false
      }
    },
    formatDateString(inputDate) {
      // "T" と "." の間の部分を抽出
      if (!inputDate) return '';
      return inputDate.split(".")[0].replace("T", " ");
    },
    changeStatusToName(str) {
      let retVal = "";
      if (!str) {
        retVal = '';
      } else if (str == 'CONFIRMED') {
        retVal = '利用中';
      } else if (str == 'FORCE_CHANGE_PASSWORD') {
        retVal = '招待中';
      }
      return retVal
    },
    changeDivisionToName(str) {
      let retVal = "";
      if (!str) {
        retVal = '';
      } else if (str == 'teacher') {
        retVal = '先生';
      } else if (str == 'admin') {
        retVal = '管理者';
      }
      return retVal
    },
  },
};
</script>
