<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">生徒詳細</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">生徒詳細</h1>
        </div>
        <div v-if="this.page_type == 'detail'" class="column his-add-btn">
          <button class="button is-info is-rounded " @click="page_push('StudentList')">管理する生徒を変更する</button>
        </div>
      </div>

      <div v-if="this.page_type == 'new'">
        <div class="columns is-centered">
          <div class="column is-10">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div class="columns has-text-centered">
                    <span class="column">
                      <button class="button is-info is-rounded" @click="page_push('StudentList')">
                        生徒を選択する
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <!--生徒選択済みの場合学習履歴を表示-->
      <div v-if="this.page_type == 'detail'">
        <div class="columns is-centered">
          <div class="column is-10">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <div class="columns">
                        <div class="column">
                          <div class="subtitle subtitle is-4">{{ this.$store.state.student_info.student_name }}</div>
                          <div class="subtitle subtitle is-5">{{ get_classroom_name(this.$store.state.student_info.classroom_id) }}:{{ this.$store.state.student_info.grade }}</div>
                        </div>
                      </div>
                    </div>
                    <span class="column std-edit">
                      <button class="button is-primary is-rounded" @click="learning_start">
                        学習を開始する
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div class="columns">
          <div class="column">
            <h1 class="title page-title">学習履歴</h1>
          </div>
          <div class="column his-add-btn">
            <button class="button is-primary is-rounded" @click="history_add">
              学習履歴の追加
            </button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-10">
            <table class="table is-fullwidth is-hoverable">
              <tr class="has-background-warning-light">
                <th>学習日</th>
                <th>学習概要</th>
                <th>詳細</th>
              </tr>
              <tr v-for="his in history_list" :key="his">
                <td>{{ formatDatetime(his.start_time) }}</td>
                <td>{{ his.other }}</td>
                <td>
                  <button class="ml-3 button is-info tb-btn" @click="his_row(his)">詳細</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      page_type: '',
      history_list: [],
    };
  },
  mounted: function () {
    let target_Student = this.$store.state.student_info;

    if (Object.keys(target_Student).indexOf('student_id') !== -1) {
      this.page_type = 'detail'
      this.getStudentHistory(target_Student.student_id);
    } else {
      this.page_type = 'new'
    }
  },
  computed: {
    get_classroom_name() {
      return (classroom_id) => {
        return this.$store.state.classroom_master[classroom_id];
      }
    }
  },
  methods: {
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    getStudentHistory: function (student_id) {
      let jsonbody = {};
      jsonbody.student_id = student_id;
      this.axios
        .post(this.$store.state.url + "history", jsonbody, this.$store.state.post_json)
        .then((res) => {
          this.history_list = res.data;
          console.log(res.data);
        });
    },
    his_row: function (history) {
      this.$router.push({ name: "LearnHisDetail", query: { student_id: history.student_id, history_id: history.history_id } });
    },
    pdf_viwe: function () {
      let result = window.confirm("学習した書籍の内容を確認しますか？");
      if (result) {
        this.$router.push({ name: "BookViewer" });
      }
    },
    learning_start: function () {
      this.$router.push({ name: "TextbookList" });
    },
    history_add: function () {
      this.$router.push({ name: "LearnHisDetail", query: { student_id: this.$store.state.student_info.student_id } });
    },
    formatDatetime(value) {
      if (!value) return '';
      return value.replace('T', ' ');
    },
  },
};
</script>
