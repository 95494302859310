<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('StudentList')">生徒一覧</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">生徒登録</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">
            生徒登録
          </h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-10">
          <table class="reg-field-table table is-fullwidth">
            <tr>
              <td class="title is-6">生徒ID</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="student_id" />
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">氏名</td>
              <td>
                <div class="control">
                  <input class="input" type="text" v-model="student_name" />
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">フリガナ</td>
              <td class="control">
                <input class="input" type="text" v-model="student_name_kana" />
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">学年</td>
              <td>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="grade">
                      <option v-for="(grade) in this.grades" :key="grade">{{ grade }}</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>

            <br />
            <tr>
              <td class="title is-6">教室</td>
              <td>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="classroom_id">
                      <option v-for="(classroom) in selectable_classroom" :key="classroom" :value="Object.keys(classroom)[0]">{{ Object.values(classroom)[0] }}</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td class="title is-6">備考</td>
              <td>
                <div class="control">
                  <textarea class="textarea" type="textarea" v-model="other"></textarea>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="columns is-centered has-text-centered">
      <div class="column is-3 learn-content">
        <button class="button is-rounded is-medium" @click="page_push('StudentList')">
          戻る
        </button>
      </div>
      <div class="column is-3 learn-content">
        <button class="button is-primary is-rounded is-medium" @click="student_create">
          登録
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      grades: [
        "小学1年",
        "小学2年",
        "小学3年",
        "小学4年",
        "小学5年",
        "小学6年",
      ],
      selectable_classroom: {},
      student_id: "",
      student_name: "",
      student_name_kana: "",
      grade: "",
      classroom_id: "",
      other: "",
    };
  },
  mounted: function () {

    //自分の所属している教室のみを抽出
    var tmparray = [];

    for (let i = 0; i < this.$store.state.my_info.custom_group.length; i++) {
      var tmpjson = {};
      var classroom_id = this.$store.state.my_info.custom_group[i];
      var value = this.$store.state.classroom_master[classroom_id];
      tmpjson[classroom_id] = value;
      tmparray.push(tmpjson);
    }

    this.selectable_classroom = tmparray;

  },
  methods: {
    student_create: function () {
      let jsonbody = {};
      jsonbody.student_id = this.student_id;
      jsonbody.student_name = this.student_name;
      jsonbody.student_name_kana = this.student_name_kana;
      jsonbody.grade = this.grade;
      jsonbody.other = this.other;
      jsonbody.classroom_id = this.classroom_id;

      this.axios
        .post(this.$store.state.url + "student/create", jsonbody, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          this.$router.push({ name: "StudentList" });
        });
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
  },
};
</script>
