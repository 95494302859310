<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('StudentDetail')">生徒詳細</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">学習履歴</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">{{ formatDatetime(this.start_time) }} 学習履歴</h1>
        </div>
      </div>
      <br />
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column is-10">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <div class="subtitle subtitle is-4">{{ this.$store.state.student_info.student_name }}</div>
                        <div class="subtitle subtitle is-5">{{ get_classroom_name(this.$store.state.student_info.classroom_id) }}:{{ this.$store.state.student_info.grade }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-10 learn-content">
          <p class="has-text-weight-semibold">管理用履歴ID:{{ history_id }}</p>
          <br>
          <p class="has-text-weight-semibold">学習形態</p>
          <table class="table">
            <tr>
              <td>
                <label class="radio">
                  <input type="radio" name="answer" checked />
                  オンライン
                </label>
              </td>
              <td>
                <label class="radio">
                  <input type="radio" name="answer" />
                  自宅支援
                </label>
              </td>
            </tr>
          </table>
          <p class="has-text-weight-semibold">学習日時</p>
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <span>利用予定日</span>
                  <input type="date" class="has-text-black" v-model="f_date" />
                </td>
                <td>
                  <span>学習開始日時</span>
                  <input type="time" class="has-text-black" v-model="f_start_time" />
                </td>
                <td>
                  <span>学習終了日時</span>
                  <input type="time" class="has-text-black" v-model="f_finish_time" />
                </td>
              </tr>
            </tbody>
          </table>
          <p class="has-text-weight-semibold">学習概要</p>
          <table class="table is-fullwidth">
            <tr class="text-area">
              <td>
                <textarea class="textarea" v-model="other"></textarea>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-11">
          <div class="tb-his">
            <table v-if="mode == 'view'" class="table is-bordered is-fullwidth">
              <tr>
                <th class="border-non"></th>
                <th>教科</th>
                <th colspan="4">テキストコード</th>
                <th>ページ</th>
                <th>実施</th>
                <th>内容</th>
                <th>メモ</th>
                <th>HW</th>
                <th></th>
              </tr>
              <tr v-for="(detail, index) in history_info['detail']" :key="detail" :draggable="true" @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent @dragenter.prevent>
                <td class="fa-lines">
                  <fa icon="fa-solid fa-grip-lines" />
                </td>
                <td contenteditable="true" @blur="onblur($event, index, 'subject')">{{ detail.subject }}</td>
                <td class="border-dashed" contenteditable="true" @blur="onblurCode($event, index, 'code', 0)">
                  {{ detail.code[0] }}
                </td>
                <td class="border-dashed" contenteditable="true" @blur="onblurCode($event, index, 'code', 1)">
                  {{ detail.code[1] }}
                </td>
                <td class="border-dashed" contenteditable="true" @blur="onblurCode($event, index, 'code', 2)">
                  {{ detail.code[2] }}
                </td>
                <td class="border-dashed" contenteditable="true" @blur="onblurCode($event, index, 'code', 3)">
                  {{ detail.code[3] }}
                </td>
                <td contenteditable="true" @blur="onblur($event, index, 'page')">{{ detail.page }}</td>
                <td>
                  <label class="checkbox">
                    <input type="checkbox" v-model="detail.do" />
                  </label>
                </td>
                <td contenteditable="true" @blur="onblur($event, index, 'content')">{{ detail.content }}</td>
                <td contenteditable="true" @blur="onblur($event, index, 'memo')">{{ detail.memo }}</td>
                <td contenteditable="true" @blur="onblur($event, index, 'HW')">{{ detail.HW }}</td>
                <td @click="list_delete(index)">
                  <fa icon="circle-xmark" />
                  <i class="fas fa-envelope"></i>
                  <!-- <button class="button">
                      <span class="icon is-small">
                        
                      </span>
                    </button> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="add-btn">
        <button class="button is-dark is-rounded is-medium" @click="learning_ditail_add">
          追加
        </button>
      </div>
      <br />
      <div class="columns is-centered">
        <div class="column is-10 learn-content">
          <p class="has-text-weight-semibold">家庭での様子・連絡事項</p>
          <table class="table is-fullwidth">
            <tr class="text-area">
              <td>
                <textarea class="textarea" v-model="memo"></textarea>
              </td>
            </tr>
          </table>
          <p class="has-text-weight-semibold">コメント</p>
          <table class="table is-fullwidth">
            <tr class="text-area">
              <td>
                <textarea class="textarea" v-model="comment"></textarea>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <div class="columns is-centered has-text-centered">
        <div class="column is-3 learn-content">
          <button class="button is-rounded is-medium" @click="page_push('StudentDetail')">
            戻る
          </button>
        </div>
        <div class="column is-3 learn-content">
          <button v-if="this.history_id" class="button is-primary is-rounded is-medium" @click="update_history_info">
            更新
          </button>
          <button v-else class="button is-primary is-rounded is-medium" @click="create_history_info">
            登録
          </button>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      student_id: "",
      history_id: "",
      start_time: "",
      finish_time: "",
      other: "",
      memo: "",
      comment: "",
      history_info: {
        "detail": []
      },
      mode: "view", // 閲覧:view, 編集:edi
      f_date: "",
      f_start_time: "",
      f_finish_time: "",
      content_num: 3,
    };
  },
  mounted: function () {
    this.student_id = this.$route.query.student_id
    this.history_id = this.$route.query.history_id
    this.get_history_info()
  },
  computed: {
    get_classroom_name() {
      return (classroom_id) => {
        return this.$store.state.classroom_master[classroom_id];
      }
    }
  },
  methods: {
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    get_history_info: function () {
      this.axios
        .post(this.$store.state.url + "history", { "student_id": this.student_id }, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);

          var history_info_all = res.data;

          for (let i = 0; i < history_info_all.length; i++) {
            if (history_info_all[i].history_id == this.history_id) {
              console.log(history_info_all[i])

              this.history_info.detail = history_info_all[i].learning_detail.detail;
              this.history_info.history_id = history_info_all[i].history_id;

              this.start_time = history_info_all[i].start_time;
              this.finish_time = history_info_all[i].finish_time;

              // 日付フォーマットの整備
              let date = history_info_all[i].start_time.split('T');
              let time = date[1].split(':');

              let finish_date = history_info_all[i].finish_time.split('T');
              let finish_time = finish_date[1].split(':');

              this.f_date = date[0];
              this.f_finish_time = finish_time[0] + ':' + finish_time[1];
              this.f_start_time = time[0] + ':' + time[1];

              this.other = history_info_all[i].other;
              this.memo = history_info_all[i].memo;
              this.comment = history_info_all[i].comment;
            }
          }
        });
    },
    update_history_info_bk: function () {
      this.axios
        .post(this.$store.state.url + "history/entry", { student_id: this.student_id }, this.$store.state.post_json)
        .then((res) => {
          console.log(res.data);
          this.history_info.detail = res.data[0].learning_detail.detail;
          this.history_info.history_id = res.data[0].history_id;

          // 日付フォーマットの整備
          let date = res.data[0].start_time.split('T');
          let finish_date = res.data[0].finish_time.split('T');
          let time = date[1].split(':');
          let finish_time = finish_date[1].split(':');

          this.date = date[0];
          this.finish_time = finish_time[0] + ':' + finish_time[1];
          this.start_time = time[0] + ':' + time[1];

          this.other = res.data[0].other;
          this.memo = res.data[0].memo;
          this.comment = res.data[0].comment;
        });
    },
    learning_ditail_add: function () {
      this.history_info.detail.push({
        subject: "",
        code: [],
        page: null,
        do: "",
        content: "",
        memo: "",
        HW: "",
        list_order: this.history_info.detail.length + 1,
      });
      // this.mode = "edit";
    },
    list_delete: function (index) {
      this.history_info.detail.splice(index, 1);
    },
    dragList(event, dragIndex) {
      console.log(dragIndex);
      event.dataTransfer.effectAllowed = "move"; //ドロップ先で行える操作
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData("drag-index", dragIndex); //ドラックしたindexを保存
    },
    dropList(event, dropIndex) {
      console.log(dropIndex);
      const dragIndex = event.dataTransfer.getData("drag-index");
      const deleteList = this.history_info.detail.splice(dragIndex, 1);
      this.history_info.detail.splice(dropIndex, 0, deleteList[0]);
    },
    update_history_info: function () {
      let jsonbody = {};
      jsonbody.student_id = this.student_id;
      jsonbody.history_id = this.history_id;
      jsonbody.start_time = this.f_date + 'T' + this.f_start_time + ':00';
      jsonbody.finish_time = this.f_date + 'T' + this.f_finish_time + ':00';
      jsonbody.other = this.other;
      jsonbody.memo = this.memo;
      jsonbody.comment = this.comment;
      let history_detail_obj = { detail: [] };
      history_detail_obj.detail = this.history_info.detail;
      jsonbody.learning_detail = history_detail_obj;

      this.axios
        .post(this.$store.state.url + "history/update", jsonbody, this.$store.state.post_json)
        .then((res) => {
          alert("履歴を更新しました。")
          //this.$router.push({ name: "StudentDetail" });
          console.log(res.data);
        });
    },
    create_history_info: function () {
      //paramチェック

      let jsonbody = {};
      jsonbody.student_id = this.student_id;
      jsonbody.start_time = this.f_date + 'T' + this.f_start_time + ':00';
      jsonbody.finish_time = this.f_date + 'T' + this.f_finish_time + ':00';
      jsonbody.other = this.other;
      jsonbody.memo = this.memo;
      jsonbody.comment = this.comment;
      let history_detail_obj = { detail: [] };
      history_detail_obj.detail = this.history_info.detail;
      jsonbody.learning_detail = history_detail_obj;

      this.axios
        .post(this.$store.state.url + "history/create", jsonbody, this.$store.state.post_json)
        .then((res) => {
          alert("履歴と登録しました。")
          //this.$router.push({ name: "StudentDetail" });
          console.log(res.data);
        });
    },
    check_validation: function () {
      if (this.student_id && this.f_date && this.f_start_time && this.f_finish_time) {
        return true
      } else {
        return false
      }
    },
    onblur: function (e, index, key) {
      this.history_info.detail[index][key] = e.target.innerText;
    },
    onblurCode: function (e, index, key, i) {
      // console.log(e.target.innerHTML);
      this.history_info.detail[index][key][i] = e.target.innerHTML;
    },
    formatDatetime(value) {
      if (!value) return '';
      return value.replace('T', ' ');
    },
  },
};
</script>
<style>
@media print {

  body,
  table {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    page-break-inside: avoid;
  }
}
</style>