<template>
  <div class="page-nav is-mobile">
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item has-text-weight-bold" v-on:click="home">
            <span>TOP</span>
          </a>
          <div class="line-left"></div>
          <a class="navbar-item has-text-weight-bold" v-on:click="list">生徒詳細</a>
        </div>

        <div class="navbar-end">
          <div class="classroom-menu">
            <div class="control has-icons-left">
              <div class="select">
                <select v-model="selected_classroom" @change="change_classroom()">
                  <option v-for="(classroom) in selectable_classroom" :key="classroom" :value="Object.keys(classroom)[0]">{{ Object.values(classroom)[0] }}</option>
                </select>
              </div>
              <span class="icon is-left">
                <fa icon="layer-group" />
              </span>
            </div>
          </div>
          <div>
            <button class="button is-rounded is-light" v-on:click="logout">
              <span class="icon is-small">
                <fa icon="sign-out-alt" />
              </span>
              <span>ログアウト</span>
            </button>
          </div>
        </div>
      </div>
    </nav>

    <div class="classroom-modal modal" v-bind:class="{ 'is-active': modal_is_active }">
      <div class="modal-background" @click="classroom_modal_close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"></p>
          <button class="delete" aria-label="close" @click="classroom_modal_close"></button>
        </header>
        <section class="modal-card-body modal-title">
          <span class="title is-4">{{ get_classroom_name(selected_classroom) }}</span>
          <span class="subtitle is-4"> に変更してTOPに戻りますか？ </span>
        </section>
        <footer class="modal-card-foot modal-btn">
          <button class="button" @click="classroom_modal_close">キャンセル</button>
          <button class="button is-success" @click="change_classroom_confirm(selected_classroom)">確定</button>
        </footer>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: "Navbar",
  data() {
    return {
      selectable_classroom: {},
      selected_classroom: "",
      before_classroom:"",
      modal_is_active:false
    };
  },
  mounted: function () {
    //現在の教室をセット
    this.selected_classroom = this.$store.state.selected_classroom;
    //セレクトボックスをキャンセルした時のためにBeforeの値を残しておかないといけない
    this.before_classroom = this.$store.state.selected_classroom;

    //自分の所属している教室のみを抽出
    var tmparray = [];

    for (let i = 0; i < this.$store.state.my_info.custom_group.length; i++) {
      var tmpjson = {};
      var classroom_id = this.$store.state.my_info.custom_group[i];
      var value = this.$store.state.classroom_master[classroom_id];
      tmpjson[classroom_id] = value;
      tmparray.push(tmpjson);
    }

    this.selectable_classroom = tmparray;
    
  },
  computed: {
    get_classroom_name() {
      return (classroom_id)=>{
        return this.$store.state.classroom_master[classroom_id];
      }
    }
  },
  methods: {
    home: function () {
      this.$router.push({ name: "Top" });
    },
    logout: function () {
      localStorage.removeItem("AccessToken");
      localStorage.removeItem("mystep");
      this.$router.push({ name: "Login" });
    },
    list: function () {
      this.$router.push({ name: "StudentDetail" });
    },
    book: function () {
      this.$router.push({ name: "BookList" });
    },
    tag: function () {
      this.$router.push({ name: "Tag" });
    },
    change_classroom: function () {
      this.modal_is_active = true;
    },
    classroom_modal_close: function () {
      this.selected_classroom = this.before_classroom 
      this.modal_is_active = false;
    },
    change_classroom_confirm: function (selected_classroom) {
      this.$store.commit("setStudentInfo", {});
      this.$store.commit("setBookInfo", {});
      this.$store.selected_classroom = selected_classroom;
      this.$store.commit("setNowClassRoom", selected_classroom);
      this.before_classroom = selected_classroom;
      this.$router.push({ name: "Top" });
      this.modal_is_active = false;
    }
  },
};
</script>