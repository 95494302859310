import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Navbar from './components/Navbar.vue'
import NavbarSystem from './components/NavbarSystem.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'vue-moment'
import {useRoute} from 'vue-router'
import draggable from 'vuedraggable'

library.add(fas)
createApp(App).use(store).use(router).use(VueAxios, axios, moment, useRoute).component('fa', FontAwesomeIcon).component('Navbar', Navbar).component('NavbarSystem', NavbarSystem).component('draggable', draggable).mount('#app')

