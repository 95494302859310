<template>
  <div>
    <Navbar />
    <div class="top" id="app">
      <div class="columns is-centered">
        <div class="column is-11">
          <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li><a @click="page_push('Top')">Top</a></li>
              <li><a @click="page_push('StudentDetail')">生徒詳細</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">教材選択</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <br>
      <div class="columns">
        <div class="column">
          <h1 class="title page-title">教材選択</h1>
        </div>
      </div>
      <br />
      <div v-show="this.$route.query['student_id'] != null">
        <div class="columns">
          <div class="column is-1"></div>
          <div class="column is-10">
            <div class="card">
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <div class="columns">
                        <div class="column">
                          <div class="subtitle subtitle is-4">生徒001さん</div>
                          <div class="subtitle subtitle is-5">
                            XX教室 小学3年
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="column std-edit">
                      <button
                        class="button is-dark is-rounded is-medium"
                        @click="student_edit"
                      >
                        編集
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <table class="table tb-search">
            <tr>
              <td class="tb-middle subtitle is-5">カテゴリ1</td>
              <td class="tb-middle s-input">
                <button class="button is-medium category-btn " @click="tag_select('国語')">国語</button>
                <button class="button is-medium category-btn" @click="tag_select('算数')">算数</button>
                <button class="button is-medium category-btn" @click="tag_select('英語')">英語</button>
              </td>
            </tr>
            <!-- <tr>
              <td class="tb-middle subtitle is-5">カテゴリ2</td>
              <td class="tb-middle s-input">
                <button class="button is-medium category-btn" @click="tag_select('漢字')">漢字</button>
                <button class="button is-medium category-btn" @click="tag_select('計算')">計算</button>
              </td>
            </tr> -->
          </table>
        </div>
      </div>
      <div class="columns">
        <div class="column is-1"></div>
        <div class="column">
          <table class="table tb-search">
            <tr>
              <td class="tb-middle subtitle is-5">検索項目:</td>
              <td class="tb-middle s-input">
                <input class="input" type="text" placeholder />
              </td>
              <td>
                <button class="button is-dark is-rounded is-medium tb-btn">
                  検索
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <hr class="hr" />
      <br />
      <div class="columns is-centered">
        <div class="column is-10">
          <div class="tile is-ancestor columns is-multiline">
            <div v-for="book in books" :key="book" class="column tile is-parent is-3 book_content" @click="select_book(book)">
              <article class="tile is-child">
                <figure class="image is-4by5">
                  <img :src="book.thumnail_url" />
                </figure>
                <p class="title is-4">{{ book.title }}</p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Navbar from "@/components/Navbar.vue";

export default {
  name: "app",
  //   components: {
  //     Navbar
  //   },
  data() {
    return {
      bookAPI: [],
      books:[],
      thumnail_url: "",
      tags: {},
      select_tags: []
    };
  },
  mounted: function () {
    this.get_book_list();
    this.get_tag_list();
  },
  methods: {
    get_book_list: function () {
      let jsonbody = {};
      this.axios
      .post(this.$store.state.url + "book",jsonbody,this.$store.state.post_url)
      .then((res) => {
        this.bookAPI = res.data;

        for (let i = 0; i < res.data.length; i++) {
          let book = res.data[i];
          console.log(book)
          if (book.title != ""){
            this.books.push(book);
          } 
        }
      });
    },
    get_tag_list: function () {
      let jsonbody = {};
      this.axios
      .post(this.$store.state.url + "tag",jsonbody,this.$store.state.post_url)
      .then((res) => {
        res.data.forEach(tag => {
          this.tags[tag.name] = tag.tag_id;
        });
      });
    },
    page_push: function (page) {
      this.$router.push({ name: page });
    },
    student_edit: function () {
      this.$router.push({
        name: "StudentReg",
        query: { student_id: this.$route.query["student_id"] },
      });
    },
    select_book: function (book) {
      this.$store.commit("setBookInfo", {"bookid":book.book_id,"title":book.title});
      this.$router.push({ name: "PageSelect" });
    },
    tag_select: function(name){
      if(this.tags[name]){
        if (this.select_tags.includes(Number(this.tags[name]))){
          this.select_tags.splice(this.select_tags.indexOf(Number(this.tags[name])), 1);
        } else {
          this.select_tags.push(Number(this.tags[name]));
        }
      }
      this.tag_filtering();
    },
    tag_filtering: function(){
      if(this.select_tags.length != 0){
        let list = [];
        this.select_tags.forEach(tag => {
          for (let index = 0; index < this.books.length; index++) {
            let book = this.books[index];
            if (book.tag_id.includes(Number(tag))){
              list.push(book);
              continue;
            } 
          }
        });
        this.books = list;
      }
    }
  },
};
</script>
